import { openMessageWithIcon } from './helper';

interface DocumentWithFullscreen extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

export function isSupportFullScreen(): boolean {
  const body = document.body as DocumentElementWithFullscreen;
  if (
    body.requestFullscreen ||
    body.msRequestFullscreen ||
    body.webkitRequestFullscreen ||
    body.mozRequestFullScreen
  )
    return true;
  return false;
}

export function isFullScreen(): boolean {
  const doc = document as DocumentWithFullscreen;
  return !!(
    doc.fullscreenElement ||
    doc.mozFullScreenElement ||
    doc.webkitFullscreenElement ||
    doc.msFullscreenElement
  );
}

interface DocumentElementWithFullscreen extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export function requestFullScreen(element: DocumentElementWithFullscreen) {
  console.log(
    '🚀 ~ file: fullscreen.ts:27 ~ requestFullScreen ~ element:',
    element,
  );
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else {
    openMessageWithIcon(
      'error',
      'Your browser does not support fullscreen mode',
    );
  }
}

export function exitFullScreen(doc: DocumentWithFullscreen) {
  if (doc.exitFullscreen) {
    doc.exitFullscreen();
  } else if (doc.msExitFullscreen) {
    doc.msExitFullscreen();
  } else if (doc.webkitExitFullscreen) {
    doc.webkitExitFullscreen();
  } else if (doc.mozCancelFullScreen) {
    doc.mozCancelFullScreen();
  }
}

export function toogleFullScreen(): void {
  if (!isFullScreen()) {
    requestFullScreen(document.body);
  } else {
    exitFullScreen(document);
  }
}
