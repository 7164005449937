/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { isServer } from '@utils/helper';
import { useGoogleAnalytics } from '@utils/hooks/useGoogleAnalytics';
import { useLeadFormCapture } from '@containers/Home/hook/useLeadFormCapture';
import { HomeWrapper } from './styled';
import {
  selectSceneSelected,
  selectDetailProject,
  selectListCallToAction,
  selectThemeConfigGlobal,
  selectIsMatterportProject,
  selectConfigProtectPassword,
  selectCallToActionConfigGlobal,
} from './selectors';
import { useCheckPasswordTour } from './hook/useCheckPasswordTour';
import { useTourEventState } from './global/TourStats';
import { useKrpano } from './hook/useKrpano';
import LoadingContainer from '@components/LoadingContainer';

const Theme = dynamic(() => import('./components/Theme'));
const Scripts = dynamic(() => import('./components/Scripts'));

const DialogPost = dynamic(() => import('@components/DialogPost'));
const ProtectPassword = dynamic(() => import('@components/ProtectPassword'));
const PopupIntro = dynamic(
  () => import('@containers/Home/components/PopupIntro'),
  { ssr: false },
);
const Collaboration = dynamic(() => import('./components/Collaboration'), {
  ssr: false,
});
const DialogFormCapture = dynamic(
  () => import('@components/DialogFormCapture'),
  { ssr: false },
);
const CallToActions = dynamic(() => import('@components/CallToActions'));

interface Props {
  isPublic?: boolean;
  isExport?: boolean;
  isCollaborationMode?: boolean;
  // NOTE: is case custom domain => not save scene on url
  isPreventChangeUrl?: boolean;
  // NOTE: fetch cookies from server
  passwordTourCookie?: string;
  listProjectGuardOpenedCookie?: string;
  listFormCaptureOpenedCookie?: string;
}

function Home({
  isPublic,
  isExport,
  isCollaborationMode = false,
  isPreventChangeUrl = false,
  passwordTourCookie,
  listProjectGuardOpenedCookie,
  listFormCaptureOpenedCookie,
}: Props) {
  const router = useRouter();
  const containerRef = useRef<HTMLDivElement>(null!);

  const detailProject = useSelector(selectDetailProject);
  const sceneSelected = useSelector(selectSceneSelected);
  const themeConfig = useSelector(selectThemeConfigGlobal);
  const listCallToAction = useSelector(selectListCallToAction);
  const isMatterportProject = useSelector(selectIsMatterportProject);
  const callToActionConfigGlobal = useSelector(selectCallToActionConfigGlobal);

  const configProtectPassword = useSelector(selectConfigProtectPassword);

  const [tourEventStats] = useTourEventState('state');
  const { onUpdatePageViewByUrl } = useGoogleAnalytics();

  const { onCloseDialogPostProject, onGetStaticTextsMultiLanguage } = useKrpano(
    {},
  );

  const { isOpenGuardPassword, onCheckGuardPassword } = useCheckPasswordTour({
    detailProject,
    configProtectPassword,
    passwordTourCookie,
    listProjectGuardOpenedCookie,
  });

  const {
    onCloseFormCapture,
    formCaptureSelected,
    isVisibleFormCapture,
    formCaptureConfigGlobal,
    formCaptureConfigSelected,
    onCheckShowFormCaptureOnScene,
    onCheckShowFormCaptureOnProject,
  } = useLeadFormCapture({
    listFormCaptureOpenedCookie,
  });

  useEffect(() => {
    if (sceneSelected && !isPreventChangeUrl && !isMatterportProject) {
      if (!isCollaborationMode) {
        if (isExport)
          window.history.pushState(
            null,
            null,
            `${process.env.NEXT_PUBLIC_SUBFOLDER || ''}/?scene=${
              sceneSelected.id
            }`,
          );
        else
          router.push(
            isPublic
              ? `${process.env.NEXT_PUBLIC_BASE_URL_PUBLIC}/${router.query.tour}/${sceneSelected.id}`
              : `${process.env.NEXT_PUBLIC_BASE_URL_PREVIEW}?tour=${router.query.tour}&scene=${sceneSelected.id}`,
            undefined,
            { shallow: true },
          );
      } else {
        const url = `${process.env.NEXT_PUBLIC_BASE_URL_COLLABORATION}?tour=${router.query.tour}&scene=${sceneSelected.id}`;
        if (!isServer) window.history.pushState(null, null, url);
      }
      onUpdatePageViewByUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneSelected?.id]);

  // CHECK FORM CAPTURE TO SHOW
  useEffect(() => {
    detailProject?.id && onGetStaticTextsMultiLanguage();
    if (isVisibleFormCapture === null && detailProject?.id)
      onCheckShowFormCaptureOnProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailProject?.id]);

  useEffect(() => {
    if (isVisibleFormCapture === false && sceneSelected)
      onCheckShowFormCaptureOnScene(sceneSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisibleFormCapture, sceneSelected?.id]);

  return isOpenGuardPassword ? (
    <ProtectPassword
      scene={sceneSelected}
      password={configProtectPassword?.password}
      onSubmit={onCheckGuardPassword}
    />
  ) : detailProject?.id ? (
    <>
      <HomeWrapper ref={containerRef}>
        {isCollaborationMode && <Collaboration />}
        <div className="mainContent">
          <Theme />

          {!!listCallToAction.length && (
            <CallToActions
              listCallToAction={listCallToAction}
              callToActionConfig={callToActionConfigGlobal}
            />
          )}

          <PopupIntro />

          {!!formCaptureSelected?.id && (
            <DialogFormCapture
              onCancel={onCloseFormCapture}
              visible={isVisibleFormCapture}
              formCaptureSelected={formCaptureSelected}
              formCaptureConfigGlobal={formCaptureConfigGlobal}
              formCaptureConfigSelected={formCaptureConfigSelected}
            />
          )}

          {tourEventStats?.idPostProjectSelected && (
            <DialogPost
              onCancel={onCloseDialogPostProject}
              key={tourEventStats?.idPostProjectSelected}
              idPost={tourEventStats?.idPostProjectSelected}
              visible={tourEventStats?.visibleDialogPostProject}
              isUseDefaultStyle={themeConfig?.selected === 'default'}
            />
          )}
        </div>
      </HomeWrapper>

      <Scripts />
    </>
  ) : (
    <LoadingContainer loading>
      <div style={{ width: '100%', height: '100%' }}>Loading</div>
    </LoadingContainer>
  );
}

export default memo(Home);
