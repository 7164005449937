import { Type_RefFuncKrpano } from '@components/Krpano/types';
import { createGlobalState } from 'react-hooks-global-state';
import { I_CommentScene, I_PayloadCommentScene } from '../types';

const { useGlobalState: useVLookatKrpano } = createGlobalState<{
  vlookat?: number;
}>({ vlookat: undefined });

const { useGlobalState: useHLookatKrpano } = createGlobalState<{
  hlookat?: number;
}>({ hlookat: undefined });

const { useGlobalState: useZoomKrpano } = createGlobalState<{ zoom?: number }>({
  zoom: undefined,
});

const { useGlobalState: useCollaborationMode } = createGlobalState<{
  loading: boolean;
  krpanoRef?: Type_RefFuncKrpano;
  commentSceneSelected?: I_CommentScene | null;
  isShowDialogCreateCommentScene?: boolean;
  commentSceneTemporary?: I_PayloadCommentScene | null;
}>({
  loading: false,
  krpanoRef: undefined,
  commentSceneSelected: null,
  isShowDialogCreateCommentScene: false,
  commentSceneTemporary: null,
});

const { useGlobalState: useTourStats } = createGlobalState<{
  stats: {
    isShowControlbar?: boolean;
    btnDirectionActive?: string;
    isGyroscopeAvailable?: boolean;
  };
}>({
  stats: {
    isShowControlbar: true, // for theme
    btnDirectionActive: '',
    isGyroscopeAvailable: false,
  },
});

const { useGlobalState: useTourEventState } = createGlobalState<{
  state: {
    isLoaded?: boolean;
    idPostProjectSelected?: string;
    visibleDialogPostProject?: boolean;
    idPostSceneSelected?: string;
    visibleDialogPostScene?: boolean;
    isSoundOnScene?: boolean;
    isVisibleMap?: boolean;
    isVisibleFloorplan?: boolean;
    isVisiblePopupIntro?: boolean;
    isActiveModeMultipleStaging?: boolean;
    descriptionHotspotSelected?: string;
  };
}>({
  state: {
    isLoaded: false,
    //
    idPostProjectSelected: '',
    visibleDialogPostProject: false,
    //
    idPostSceneSelected: '',
    visibleDialogPostScene: false,
    isSoundOnScene: false,
    //
    isVisibleMap: true,
    isVisibleFloorplan: true,
    isVisiblePopupIntro: undefined,
    isActiveModeMultipleStaging: false,
    // hotspot compact when hover
    descriptionHotspotSelected: undefined,
  },
});

export {
  useTourStats,
  useZoomKrpano,
  useHLookatKrpano,
  useVLookatKrpano,
  useTourEventState,
  useCollaborationMode,
};
